<template>
  <div class="landing">
    <div class="header">
      <v-layout fill-height align-center>
        <div>
          <div class="header-btn" @click="moveForm"><img src="/img/pencil.svg" class="btn-icon"></div>
        </div>
        <v-flex class="text-center">
          <img src="/img/logo2.png" class="logo">
        </v-flex>
        <div>
          <div class="header-btn">
            <a href="tel:16004452">
              <img src="/img/phone.svg" class="btn-icon">
            </a>

          </div>
        </div>
      </v-layout>
    </div>


    <div id="section1" class="section">
      <img src="/img/txt1_1.png" class="txt-img" data-aos="flip-left">
    </div>
    <div id="section2" class="section">
      <img src="/img/img1.png" class="img" data-aos="fade-up">
    </div>
    <div id="section3" class="section">
      <img src="/img/txt2.png" class="txt-img" data-aos="fade-up">
    </div>
    <div id="section4" class="section">
      <img src="/img/txt3.png" class="txt-img" data-aos="fade-up">
    </div>
    <div id="section5" class="section">
      <img src="/img/txt4.png" class="txt-img" data-aos="fade-up">
    </div>
    <div id="section6" class="section">
      <img src="/img/img2.png" class="img" data-aos="fade-up">
    </div>
    <div id="section7" class="section">
      <a href="tel:16004452">
        <img src="/img/txt5.gif" class="txt-img" data-aos="fade-up">
      </a>
    </div>
    <div id="section8" class="section" data-aos="fade-up">
      <img src="/img/img3.png" class="img" data-aos="fade-up">
    </div>
    <div id="form" ref="form" data-aos="fade-up">
      <v-text-field class="input" v-model="form.이름" name="name" placeholder="이름" solo flat hide-details></v-text-field>
      <v-text-field class="input" v-model="form.연락처" name="phone" placeholder="연락처" solo flat hide-details></v-text-field>
      <v-text-field class="input" v-model="form.거주지" name="address" placeholder="거주지" solo flat
        hide-details></v-text-field>

      <v-layout class="pt-4 checkbox" align-center>
        <app-checkbox v-model="agree" class="mr-1"></app-checkbox>
        <!-- <v-checkbox dark hide-details class="ma-0 pa-0"></v-checkbox> -->
        <div class="label" @click="agree = !agree">개인정보 수집 및 이용 동의</div>
        <div class="label-btn" @click="openPrivacyDialog()">(보기)</div>
      </v-layout>

      <div class="text-center">
        <div class="submit-btn mt-2" v-ripple @click="submit">신청하기</div>
      </div>
    </div>
    <div id="section9" class="section">
      <img src="/img/txt6.png" class="img" data-aos="fade-up">
    </div>
    <div id="section10" class="section">
      <img src="/img/img7.png" class="img" data-aos="fade-up">
    </div>

    <div id="section11" class="section">
      <img src="/img/txt7.png" class="txt-img" data-aos="fade-up">
    </div>
    <div id="section12" class="section" data-aos="fade-up">
      <img src="/img/img8.gif" class="img">
    </div>

    <div id="form" ref="form" data-aos="fade-up">
      <v-text-field class="input" v-model="form.name" name="name" placeholder="이름" solo flat hide-details></v-text-field>
      <v-text-field class="input" v-model="form.phone" name="phone" placeholder="연락처" solo flat
        hide-details></v-text-field>
      <v-text-field class="input" v-model="form.address" name="address" placeholder="거주지" solo flat
        hide-details></v-text-field>

      <v-layout class="pt-4 checkbox" align-center>
        <app-checkbox v-model="agree" class="mr-1"></app-checkbox>
        <!-- <v-checkbox dark hide-details class="ma-0 pa-0"></v-checkbox> -->
        <div class="label" @click="agree = !agree">개인정보 수집 및 이용 동의</div>
        <div class="label-btn" @click="openPrivacyDialog()">(보기)</div>
      </v-layout>

      <div class="text-center">
        <div class="submit-btn mt-2" v-ripple @click="submit">신청하기</div>
      </div>
    </div>
    <div id="footer">
      <img src="/img/footer.png" class="img">
    </div>



    <v-dialog width="500px" v-model="privacyDialog.visible" content-class="privacy-dialog">
      <v-card>
        <div class="dialog-header">개인정보 수집 및 이용 동의</div>
        <div class="body">

          수집하는 개인정보의 항목<br>
          -1. 이름<br>
          -2. 연락처<br>
          -3. 거주지<br><br>

          정보 제공을 위한 목적이 소멸되면 자료를 파기합니다.<br>
          개인정보의 수집 및 이용에 대한 동의를 거부할 수 있으며, 이 경우 신청에 제한이 될 수 있음을 알려드립니다.<br>
          동의하시려면 체크박스에 체크해주세요


        </div>
        <div class="text-center pb-4">
          <div class="submit-btn" @click="closePrivacyDialog()">확인</div>
        </div>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Vue from "vue";
import AOS from "aos";
import axios from "axios";
import moment from "moment"
import "aos/dist/aos.css";
import AppCheckbox from "@/components/AppCheckbox.vue";

export default {
  components: {
    AppCheckbox
  },
  data() {
    return {
      agree: false,
      test: "test",
      form: {
        formId: "fe242b40f046c40a1126a13505a385eed1d9610723b3060e94d0cb6e3baa38512f2d8ab0a42a37b0f9bc9846c5e22219",
        이름: '',
        연락처: '',
        거주지: '',
        작성일: moment().format('YYYY-MM-DD HH:mm:ss')
      },
      privacyDialog: {
        visible: false
      }
    }
  },
  mounted() {
    Vue.nextTick(() => {
      AOS.init({
        duration: 800,

      });
    })
  },
  methods: {
    async submit() {
      if (!this.agree) {
        alert('개인정보 수집 및 이용에 동의해주세요')
        return
      }
      if (this.form.이름.length < 1 || this.form.연락처.length < 1 || this.form.거주지.length < 1) {
        alert('모든 항목을 입력해주세요')
        return
      }
      this.form.작성일 = moment().format('YYYY-MM-DD HH:mm:ss')
      console.log("????")
      // let { data } = await axios.post('http://localhost:5800/api/v1/form/submit', this.form)
      let { data } = await axios.post('https://hhsoft.co.kr/api/v1/form/submit', this.form)
      if (data) {
        alert('신청이 완료되었습니다')
        this.formClear()
      }

      // alert('신청이 완료되었습니다')
    },
    formClear() {
      this.form.이름 = ''
      this.form.연락처 = ''
      this.form.거주지 = ''
      this.agree = false
    },
    closePrivacyDialog() {
      this.privacyDialog.visible = false
    },
    openPrivacyDialog() {
      this.privacyDialog.visible = true
    },
    moveForm() {
      this.$refs.form.scrollIntoView({ behavior: 'smooth' })
      // document.getElementById('form').scrollIntoView({ behavior: 'smooth' });
    }
  }

}
</script>
<style lang="less" scoped>
@point-color: #c72728;

.landing {
  width: 700px;
  max-width: 100%;

  margin: 0 auto;

}

.header {
  background: white;
  height: 60px;
  padding: 0 10px;

  .header-btn {
    width: 50px;
    height: 50px;
    padding: 10px;
  }

  .logo {
    width: 160px;
  }
}

.section {
  text-align: center;



  .txt-img {
    // margin: 20px;
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
  }

  .img {
    padding: 0;
    width: 100%;
  }
}

#section1 {
  padding: 30px 20px;


}

#section2 {
  padding: 0;



}

#section4 {
  padding: 0;

  .txt-img {
    padding: 0;
  }
}

#section5 {
  margin-top: 30px;
  padding: 0;
  margin-bottom: -9px;
  height: initial;

  .txt-img {
    padding: 0;
  }
}

#form {
  padding: 20px;

  .input {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;

    background: white;
  }
}

.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;

  .label {
    color: white;

  }

  .label-btn {
    margin-left: 5px;
    color: white;
    cursor: pointer;
  }
}

.submit-btn {
  background: @point-color;
  color: white;
  // line-height: 50px;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 60px;
}


.privacy-dialog {
  .dialog-header {
    background: @point-color;
    color: white;
    padding: 10px;
  }

  .body {
    padding: 20px;
    //pre 태그처럼 보이게 하기
    word-break: break-all;

  }
}

#footer {
  width: 100%;
  height: initial;


  .img {
    margin-bottom: -12px;
    width: 100%;
  }

  // margin-bottom: -20px;
}
</style>