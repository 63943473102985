<template>
    <div @click="toggle">
        <div class="wrapper" v-if="value">
            <img class="checkbox-image" src="/img/checkbox-marked.svg" alt="">
        </div>
        <div class="wrapper" v-else>
            <img class="checkbox-image" src="/img/checkbox-blank-outline.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: ["value", "dark"],
    data() {
        return {
        }
    },
    methods: {
        toggle() {
            this.$emit("input", !this.value);
        }
    }
}
</script>

<style scoped lang="less">
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;


    img {
        width: 24px;
        height: 24px;
        color: white;
        //색상 반전시키기
        filter: invert(1);
    }
}

.checkbox-image {
    width: 24px;
    height: 24px;
    color: white;
    //색상 반전시키기
    filter: invert(1);
}
</style>